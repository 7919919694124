import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import AuthServices from '../services/auth/AuthServices'

const VerifyEmailPage = ({location}) => {

    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    const [isLoading, setIsLoading] = useState(true);
    const [verificationResult, setVerificationResult] = useState('');

    useEffect(() => {
        const record  ={
            "token" : token
        }
		AuthServices.verifyEmail(record).then((response) => {
			setIsLoading(false)
            setVerificationResult(true)
		}).catch((error) => {
			setIsLoading(false)
            setVerificationResult(false)
		});     
    }, [token, verificationResult]);    


    return(
        <>
            <Header />

            {/* Hero Section Starts */}
            <section className="text-gray-600 body-font">
                <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                    <div className="text-center lg:w-2/3 w-full">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Verify your Email</h1>
                        { !verificationResult && 
                            <div>
                                <p className="flex mb-2 leading-relaxed justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-red-900 h-32 w-32" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                    </svg>
                                </p>
                                <p className="text-xl font-bold text-red-900 mb-8">
                                    Invalid
                                </p>
                            </div>
                        }
                        { verificationResult && 
                            <div>
                                <p className="flex mb-2 leading-relaxed justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-green-700 h-32 w-32" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                    </svg>       
                                </p>
                                <p className="text-xl font-bold text-green-700 mb-8">
                                    Successfully Verified
                                </p>
                            </div>
                        }
                        <div className="flex justify-center">
                            <Link to="/app/login" className="px-8 py-2 text-white bg-blue-500 rounded shadow-xl">Login</Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* Hero Section Ends */}

            <Footer />
        </>
    )
}

export default VerifyEmailPage

